import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function Home() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  return (
    <div className="main" style={{ justifyContent: "center", gap: "10px"}}>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <div className="intro-text">Hello, my Name is Niclas</div>
      {isMobile && 
        <div>
          <Link to="/curriculum-vitae">CV · </Link>
          <Link to="/about">About · </Link>
          <Link to="/projects">Projects · </Link>
          <Link to="/inspiration">Inspiration · </Link>
          <Link to="/contact">Contact</Link>
        </div>
      }
    </div>
  );
}