import React from "react";
import "./home.css";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useData } from "../contexts/datacontext";
import { Helmet } from "react-helmet";

export default function Projects() {

  const { projects } = useData();

  return (
    
    <div className="projects-main">
      <Helmet>
        <title>Projects</title>
      </Helmet>
      <div className="flex-col items-center">
          <div className="massive font-semibold">Personal projects</div>
      </div>
      <div className="projectsRow">
        {projects.map((project) => (
          <Link to={`/project/${project.id}`} className="alternative" key={project.id}>
            <img src={project.backgroundIMG} alt="b-img" className="projectCover" />
            <div className="flex-row justify-between" style={{width: "90%", position: "relative"}}>
              <div className="projectInfo">
                <div className="project-title">{project.title_short}</div>
                <div className="regular">{project.title_long}</div>
                <div className="project-tile-more">
                  <div>See more</div>
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}